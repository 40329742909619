<template>
  <div>
  </div>
</template>

<script>
  import {post} from "@/api/http";
  export default {
      name: "userSelete",
      props:{
      },
      data(){
          return{

          }
      },
      watch:{
      },
      methods:{
      },
      mounted(){
      }
  }
</script>

<style scoped>
div{
  background: #ECECF4;
}
</style>
